import { defineStore } from "pinia";

export { useAdministrationStore } from "./administration";
export { useAuthStore } from "./auth";
export { useNotificationStore } from "./notification";
export { usePortfolioApprovalStore } from "./portfolio-approval";
export { usePortfolioStore } from "./portfolio";
export { useSimulationStore } from "./simulation";
export { useTickerStore } from "./ticker";
export { useForecastStore } from "./forecast";
export { useCurrencyStore } from "./currency";

type ToastItem = {
	message: string;
	type: string;
	timeout?: number;
};
type ConfirmationItem = {
	message: string;
	title?: string;
	okText?: string;
	cancelText?: string;
	onOk?: () => void;
	onCancel?: () => void;
};

export const useMainStore = defineStore("main", {
	state: (): {
		navDrawer: boolean;
		pageTitle: string;
		toastr: ToastItem[];
		confirmation: ConfirmationItem | null;
	} => ({
		navDrawer: false,
		pageTitle: "",
		toastr: [],
		confirmation: null,
	}),

	actions: {
		/**
		 * Adds a toastr message with timeout.
		 * @param  {String} options.message Message body
		 * @param  {String} options.type    success, info, warning, error...
		 * @param  {Number} options.timeout Default 3000
		 * @return {Void}
		 */
		addToastr({
			message,
			type = "error",
			timeout = 3000,
		}: Partial<ToastItem> & { message: string }) {
			const item = { message, type };
			this.toastr.push(item);
			setTimeout(() => this.removeToastr(item), timeout);
		},
		removeToastr(item: ToastItem) {
			this.toastr.splice(this.toastr.indexOf(item), 1);
		},
		/**
		 * Returns a promise resolved with confirmation result if onOk and onCancel
		 * not provided. If custom onOk and onCancel listeners are provided,
		 * dialog needs to be set to null manually.
		 * @param  {String}   options.message    Message to show
		 * @param  {String}   options.title      Dialog title
		 * @param  {String}   options.okText     Ok button's text
		 * @param  {String}   options.cancelText Cancel button's text
		 * @param  {Function} options.onOk       Function to call when Ok is clicked
		 * @param  {Function} options.onCancel   Function to call when Cancel is clicked
		 * @return {Promise}
		 */
		askConfirm({
			message,
			// title = "Onayınız gerekiyor",
			okText = "Tamam",
			cancelText = "İptal",
			onOk,
			onCancel,
		}: ConfirmationItem) {
			if (onOk && onCancel) {
				this.confirmation = {
					message,
					onOk,
					onCancel,
					okText,
					cancelText,
				};
				return;
			}
			return new Promise((res) => {
				onOk = () => {
					res(true);
					this.confirmation = null;
				};
				onCancel = () => {
					res(false);
					this.confirmation = null;
				};
				this.confirmation = {
					message,
					onOk,
					onCancel,
					okText,
					cancelText,
				};
			});
		},
	},
});
