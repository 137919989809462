import axios from "axios";
import router from "../router";
import AppError from "./AppError";
import { getRestApiURL, parseJWT } from "./utils";
const axiosInstance = axios.create({ baseURL: getRestApiURL() });
import useTranslate from "@/composables/useTranslate";
import { useMainStore } from "@/store";
import { useAuthStore } from "@/store/auth";

export default axiosInstance;

const PUBLIC_ENDPOINTS = [
	"/auth/login",
	"/auth/refresh",
	"/auth/sso",
	"/auth/send-reset-password-link",
	"/auth/recover-password",
];

const DO_NOT_REDIRECT_WHEN_FAILS = ["/auth/login", "/sso"];

axiosInstance.interceptors.request.use(async (config) => {
	const authStore = useAuthStore();

	if (!PUBLIC_ENDPOINTS.includes(config.url)) {
		const unauthorizedError = new AppError({
			name: "Unauthorized",
			message: "Unauthorized",
			status: 401,
		});
		const urlParams = new URLSearchParams(window.location.search);
		const returnTo = urlParams.get("returnTo")
			? urlParams.get("returnTo")
			: location.pathname + location.search;

		const { accessToken, refreshToken } = authStore;

		// Redirect to login if there's no token
		if (!accessToken || !refreshToken) {
			if (router.currentRoute.name !== "Login") {
				router.push({ name: "Login", query: { returnTo } });
			}
			throw unauthorizedError;
		}

		const accExp = (parseJWT(accessToken).exp - 5) * 1000;
		// Check if access token has expired yet
		if (accExp < Date.now()) {
			const refExp = (parseJWT(refreshToken).exp - 5) * 1000;

			// Redirect to login if both access and refresh token is expired
			if (refExp < Date.now()) {
				if (router.currentRoute.name !== "Login") {
					router.push({ name: "Login", query: { returnTo } });
				}
				unauthorizedError.message = "Tokens are expired";
				throw unauthorizedError;
			}

			try {
				await authStore.refreshAccessToken();
			} catch (err) {
				router.push({ name: "Login", query: { returnTo } });
				throw unauthorizedError;
			}
		}

		config.headers.Authorization = `Bearer ${authStore.accessToken}`;
	} else {
		// Don't need to append Authorization header to public endpoints
		delete config.headers["Authorization"];
	}

	return config;
});

axiosInstance.interceptors.response.use(
	async (response) => {
		return response;
	},
	async (error) => {
		if (error?.response?.status === 503) {
			// under maintenance
			return window.location.reload();
		}

		const authStore = useAuthStore();
		const mainStore = useMainStore();
		const { t } = useTranslate();

		let err =
			error instanceof AppError ? error.export() : error?.response?.data;

		if (!err) {
			err = error;
		}

		const urlParams = new URLSearchParams(window.location.search);
		const returnTo = urlParams.get("returnTo")
			? urlParams.get("returnTo")
			: location.pathname + location.search;

		if (err?.status === 401) {
			await authStore.logout();
			if (err?.name === "SubscriptionExpiredError") {
				router.push({ name: "SubscriptionExpired" });
			} else {
				const url = error?.response?.config?.url;
				if (!(url && DO_NOT_REDIRECT_WHEN_FAILS.includes(url))) {
					if (router.currentRoute.name !== "Login") {
						router.push({ name: "Login", query: { returnTo } });
					}
				}
			}
		}

		if (err?.status === 502) {
			const status = await mainStore.askConfirm({
				message: t("An error has occurred please refresh the page to try again")
					.value,
				okText: t("Refresh").value,
				cancelText: t("Cancel").value,
			});

			if (status) {
				window.location.reload();
			}
		}

		throw new AppError(err);
	},
);
